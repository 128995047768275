import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function TWDelayConfirmation({ show, handleClose }) {
    
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Delay Notification</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Alert className='text-center'>
                <p>Success!<br/></p>
                <p>Please allow up to <b>15 minutes for the changes you made to show in this application.</b> All changes require a sync to TempWorks before they can be visible in our applications.<br/></p>
                <p>Thank you for your patience</p>
            </Alert>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" className='full-width' onClick={()=>handleClose()}>
                Close
            </Button>
        </Modal.Footer>
  </Modal>
  )
}

export default TWDelayConfirmation
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MonthlyTab from "../CustomComponents/Tabs/MonthlyTab";
import DataTab from "../CustomComponents/Tabs/DataTab";
import "../CustomComponents/Tabs/Tabs.css";

export default function AttritionDashboardView({
  selectedTab,
  setSelectedTab,
  selectedEndsType,
  setSelectedEndsType,
  columnDefs,
  tabApiUrl,
  reportDescription,
  seriesChartTypes
}){
  return(
    <div>
      <Tabs activeKey={selectedTab} onSelect={(tab) => setSelectedTab(tab)}>
        <Tab eventKey="Monthly" title="Monthly">
          {selectedTab === "Monthly" && (
            <MonthlyTab
              columnDefs={columnDefs}
              apiUrl={tabApiUrl}
              chartTitle="Monthly Attrition"
              chartType="columnLineCombo"
              reportDescription={reportDescription}
              seriesChartTypes={seriesChartTypes}
              selectedEndsType={selectedEndsType}
              setSelectedEndsType={setSelectedEndsType}
            />
          )}
        </Tab>
        <Tab eventKey="Data" title="Data">
          {selectedTab === "Data" && (
            <DataTab
              columnDefs={columnDefs}
              apiUrl={tabApiUrl}
              chartTitle={"Attrition"}
              reportDescription={reportDescription}
            />
          )}
        </Tab>
      </Tabs>
    </div>
  )
}
import React, { useState, useEffect } from "react";
import TabTemplate from "./TabTemplate/TabTemplate";
import useFetchGet from "../../../Fetch/useFetchGet";
import Spinner from "react-bootstrap/Spinner";

export default function DataTab({
  columnDefs,
  apiUrl,
  rangeChartSeries,
  chartTitle,
  reportDescription,
}) {
  const { REACT_APP_API_URL } = process.env;
  const [dates, setDates] = useState();
  const { data: unformattedWeekendDates } = useFetchGet(
    `${REACT_APP_API_URL}/Reports/GetSundays`,
    { weeks: 52 }
  );

  useEffect(() => {
    if (unformattedWeekendDates) {
      let formattedWeekendDates = unformattedWeekendDates.map((obj) => {
        return { value: obj.TheDate, label: obj.DateValue };
      });
      setDates(formattedWeekendDates);
    }
    // eslint-disable-next-line
  }, [unformattedWeekendDates]);

  return (
    <>
      {dates ? (
        <TabTemplate
          startDateIndex={3}
          endDateIndex={0}
          dateType="week"
          apiUrl={apiUrl}
          rangeChartSeries={rangeChartSeries}
          rangeChartID={"weeklyChart"}
          columnDefs={columnDefs}
          chartTitle={chartTitle}
          dataTab={true}
          reportDescription={reportDescription}
        />
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}

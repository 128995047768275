import React, { useContext, useEffect, useState } from "react";
import * as echarts from "echarts";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactSelect from "react-select";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { BsBugFill, BsQuestionCircle, BsDownload } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import "./EndOverview.css";
import useFetchPost from "../../Fetch/useFetchPost";
import CustomerContext from "../../Context/CustomerContext";
import DepartmentDropdown from "../CustomComponents/Dropdowns/DepartmentDropdown";
import TooltipPopup from "../CustomComponents/Buttons/TooltipPopup";

const EndOverview = () => {
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tooltipPopupOpen, setTooltipPopupOpen] = useState(false);
  const [reportDescription] = useState(
    <div>
      <p>
        This report displays the reasons why employee assignments ended, and
        groups them by end reason in a sunburst chart.{" "}
      </p>
      <p>
        The innermost circle notes the End Attrition. Moving outward from the
        center, the next circle is made up of End Categories. The outermost
        circle is made up of End Reasons, which are abbreviated for readability.
        To see the full End Reason, you can hover over a slice of the chart, or
        refer to the legend located below the chart.
      </p>
    </div>
  );

  return (
    <>
      {tooltipPopupOpen && (
        <TooltipPopup
          show={tooltipPopupOpen}
          setShow={setTooltipPopupOpen}
          reportTitle={"End Overview"}
          reportDescription={reportDescription}
        />
      )}
      <Row>
        <Col>
          {loading && (
            <Alert>
              Getting data... <Spinner animation="border" size="sm" />
            </Alert>
          )}
        </Col>
        <Col sm={3}>
          <ButtonGroup className="full-width">
            <Button
              title="Show all filters related to chart"
              variant="dark"
              onClick={() => setShowFilters(!showFilters)}
            >
              {showFilters ? "Hide Filters" : "Show Filters"}
            </Button>
            <Button
              variant="dark"
              title="What is this chart?"
              onClick={() => setTooltipPopupOpen(true)}
            >
              <BsQuestionCircle />
            </Button>
            <Button
              variant="dark"
              title="Report a bug"
              onClick={(e) => {
                window.location.href = `mailto:${"Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>"}`;
                e.preventDefault();
              }}
            >
              <BsBugFill />
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <SunburstWithGrid
            chartID={"firstSunburst"}
            showFilters={showFilters}
            setFilterLoading={setLoading}
          />
        </Col>
        <Col>
          <SunburstWithGrid
            chartID={"secondSunburst"}
            showFilters={showFilters}
            setFilterLoading={setLoading}
          />
        </Col>
      </Row>
    </>
  );
};

const SunburstWithGrid = ({ chartID, showFilters, setFilterLoading }) => {
  const { cookies, setCurrentPage } = useContext(CustomerContext);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState();
  const [selectedWorksites, setSelectedWorksites] = useState();
  const [selectedBranches] = useState([
    cookies.get("customer").runAsCustomer
      ? cookies.get("customer").runAsCustomer.CustomerId
      : cookies.get("customer").CustomerID,
  ]);
  const [postObj, setPostObj] = useState();
  const [legendData, setLegendData] = useState();
  const [dates, setDates] = useState();
  const [gridApi, setGridApi] = useState();
  const { REACT_APP_API_URL } = process.env;
  const { data: weeklyData, loading } = useFetchPost(
    postObj && `${REACT_APP_API_URL}/Reports/GetCustomerEndReasons`,
    null,
    postObj
  );
  const [defaultColDef] = useState({
    sortable: true,
    resizeable: true,
  });
  const [columnDefs] = useState([
    { field: "EndAttrition", headerName: "Attrition Type" },
    { field: "EndCategory" },
    { field: "EndReason" },
    { field: "EndReasonAbbrev", headerName: "Abbreviated Reason" },
    { field: "EmployeeId", headerName: "EmployeeID" },
    { field: "AlternateID", filter: "agSetColumnFilter" },
    { field: "EmployeeName", headerName: "Employee" },
    { field: "Shift" },
    { field: "Department" },
    { field: "Worksite", filter: "agSetColumnFilter" },
    { field: "Supervisor" },
    { field: "VendorName", headerName: "Vendor" },
  ]);
  const [defaultLegendColumnDefs] = useState({
    sortable: true,
    resizable: true,
  });
  const [legendColumnDefs] = useState([
    { field: "EndCategory", headerName: "Category" },
    { field: "EndReasonAbbrev", headerName: "Abbr Reason" },
    { field: "EndReason", headerName: "Reason" },
    { field: "Count" },
    {
      field: "Percentage",
      valueFormatter: (params) => {
        return (params.value * 100).toFixed(2) + "%";
      },
    },
  ]);

  useEffect(() => {
    setCurrentPage("End Overview");
  }, [setCurrentPage]);

  useEffect(() => {
    if (dates && selectedDepartments) {
      if (!postObj) {
        setPostObj({
          StartDate: dates[2].value,
          EndDate: dates[0].value,
          DateType: "Month",
          CustomerId: selectedDepartments
            ? [selectedDepartments.value.CustomerID]
            : [],
          AddrId: selectedWorksites
            ? selectedWorksites.map((obj) => obj.value.AddrId)
            : [],
        });
      }
    }
    // eslint-disable-next-line
  }, [dates, selectedDepartments]);

  useEffect(() => {
    setFilterLoading(loading);
  }, [loading, setFilterLoading]);

  useEffect(() => {
    if (!dates) {
      let newDates = makeDates(12);
      setDates(newDates);
      setStartDate(newDates[2]);
      setEndDate(newDates[0]);
    }
    // eslint-disable-next-line
  }, [setDates]);

  const makeDates = (howMany) => {
    let dates = [];
    for (let index = 0; index <= howMany; index++) {
      if (index === 0) {
        let today = new Date();
        let firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        dates.push({
          value: firstDayOfMonth.toJSON(),
          label: firstDayOfMonth.toLocaleDateString(undefined, {
            month: "long",
            year: "numeric",
          }),
        });
        today.getMonth();
      } else {
        let previousMonthInArray = new Date(dates[index - 1].value);
        let previousMonthToAdd = new Date(
          previousMonthInArray.getFullYear(),
          previousMonthInArray.getMonth() - 1,
          1
        );
        dates.push({
          value: previousMonthToAdd.toJSON(),
          label: previousMonthToAdd.toLocaleDateString(undefined, {
            month: "long",
            year: "numeric",
          }),
        });
      }
    }
    return dates;
  };

  useEffect(() => {
    if (weeklyData) {
      BuildTheLegend(weeklyData.data);
      BuildTheChart(weeklyData, chartID);
    }
    // eslint-disable-next-line
  }, [weeklyData]);

  const BuildTheLegend = (data) => {
    let dividedArrays = {};
    data.forEach((obj) => {
      if (dividedArrays[obj.EndReason]) {
        dividedArrays[obj.EndReason].Count += 1;
        dividedArrays[obj.EndReason].Percentage =
          dividedArrays[obj.EndReason].Count / data.length;
      } else {
        obj.Count = 1;
        obj.Percentage = 1 / data.length;
        dividedArrays[obj.EndReason] = obj;
      }
    });
    let legendRows = Object.values(dividedArrays);
    setLegendData(legendRows.sort(compareFn));
  };

  function compareFn(a, b) {
    if (a.EndCategory < b.EndCategory) {
      return -1;
    }
    if (a.EndCategory > b.EndCategory) {
      return 1;
    }
    // a must be equal to b
    return a.EndReason.localeCompare(b.EndReason);
  }

  const HandleDateChange = (e, dateType) => {
    if (postObj) {
      if (dateType === "start") {
        setStartDate(e);
        let newPostObj = { ...postObj };
        newPostObj.StartDate = e.value;
        setPostObj(newPostObj);
      } else {
        setEndDate(e);
        let newPostObj = { ...postObj };
        newPostObj.EndDate = e.value;
        setPostObj(newPostObj);
      }
    }
  };

  const HandleSelectChange = (e, selectName) => {
    if (selectName === "department") {
      if (postObj) {
        let newPostObj = { ...postObj };
        newPostObj.CustomerId = [e.value.CustomerID];
        setPostObj(newPostObj);
      }
      setSelectedDepartments(e);
    } else if (selectName === "worksite") {
      setSelectedWorksites(e);
      updateFilterModel(e, "Worksite");
    }
  };

  const updateFilterModel = (event, filterInstance) => {
    let newModel = event.map((source) => source.label);
    let sourceInstance = gridApi.getFilterInstance(filterInstance);
    sourceInstance.setModel({ values: newModel });
    sourceInstance.applyModel();
    gridApi.onFilterChanged();
  };

  const rowClassRules = {
    "green-highlight": function (params) {
      if (params.node.aggData) {
        return params.node.aggData.EndAttrition === "Positive";
      } else if (params.data) {
        return params.data.EndAttrition === "Positive";
      }
    },
    "red-highlight": function (params) {
      if (params.node.aggData) {
        return params.node.aggData.EndAttrition === "Negative";
      } else if (params.data) {
        return params.data.EndAttrition === "Negative";
      }
    },
  };

  const onLegendDataRendered = (params) => {
    params.api.sizeColumnsToFit();
  };

  const buildFilterOptions = (params, filterInstance) => {
    let siteValues = params.api
      .getFilterInstance(filterInstance)
      .getFilterValues()
      .map((site) => {
        return { value: site.trim(), label: site };
      });
    return siteValues;
  };

  const onFirstDataRendered = (params) => {
    setGridApi(params.api);
    params.columnApi.autoSizeAllColumns();

    let worksiteOptions = buildFilterOptions(params, "Worksite");
    setWorksiteOptions(worksiteOptions);
    setSelectedWorksites(worksiteOptions);
  };

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const setSelectedItems = (params, instanceName, defaultArray) => {
    let instance = params.api.getFilterInstance(instanceName);
    if (instance.getModel()) {
      let selectedOptionsFromGrid = instance.getModel().values;
      let formattedOptions = selectedOptionsFromGrid.map((string) => {
        return { value: string, label: string };
      });
      return formattedOptions;
    } else {
      return defaultArray;
    }
  };

  const onFilterChanged = (params) => {
    setSelectedWorksites(setSelectedItems(params, "Worksite", worksiteOptions));
  };

  return (
    <>
      <Row className={showFilters ? "show" : "hide"}>
        <Col>
          <Form.Label>Date Range From:</Form.Label>
          <ReactSelect
            disabled={loading}
            options={dates}
            value={startDate}
            onChange={(e) => HandleDateChange(e, "start")}
          />
        </Col>
        <Col>
          <Form.Label>To:</Form.Label>
          <ReactSelect
            disabled={loading}
            options={dates}
            value={endDate}
            onChange={(e) => HandleDateChange(e, "end")}
          />
        </Col>
      </Row>
      <Row className={showFilters ? "show" : "hide"}>
        <Col>
          <Form.Label>Department:</Form.Label>
          <DepartmentDropdown
            isMulti={false}
            selectedDepartments={selectedDepartments}
            onChange={(e) => HandleSelectChange(e, "department")}
            branchIDs={selectedBranches}
          />
        </Col>
        <Col>
          <Form.Label>Work Sites:</Form.Label>
          <ReactSelect
            isMulti
            value={selectedWorksites}
            options={worksiteOptions}
            onChange={(e) => HandleSelectChange(e, "worksite")}
          />
        </Col>
      </Row>
      {selectedDepartments || selectedWorksites ? (
        <>
          <Row style={{ width: "100%", height: "35vw" }}>
            <Col id={chartID}></Col>
          </Row>

          <div className="ag-theme-alpine sunburst-legend">
            {weeklyData && (
              <AgGridReact
                rowData={legendData}
                columnDefs={legendColumnDefs}
                rowClassRules={rowClassRules}
                onFirstDataRendered={onLegendDataRendered}
                defaultColDef={defaultLegendColumnDefs}
                // domLayout={'autoHeight'}
                multiSortKey={"ctrl"}
              />
            )}
          </div>

          <Row>
            <Col className="overview-download-button-wrapper">
              <Button
                variant="dark"
                onClick={() => onBtnExport()}
                title="Download grid data"
              >
                <BsDownload />
              </Button>
            </Col>
          </Row>

          <div className="ag-theme-alpine sunburst-grid">
            {weeklyData && (
              <AgGridReact
                rowData={weeklyData.data}
                columnDefs={columnDefs}
                onFirstDataRendered={onFirstDataRendered}
                defaultColDef={defaultColDef}
                onFilterChanged={onFilterChanged}
              />
            )}
          </div>
        </>
      ) : (
        <>
          {cookies.get("customer").runAsCustomer ? (
            <Alert className="text-center">
              <Alert.Heading>
                Please select a Department or WorkSite
              </Alert.Heading>
            </Alert>
          ) : (
            <>
              <Alert className="text-center">
                <Alert.Heading>
                  You must impersonate a customer in order to see these reports
                </Alert.Heading>
                <p>
                  In order to access the impersonation menu, please click the
                  dropdown next to your name in the header
                </p>
              </Alert>
            </>
          )}
        </>
      )}
    </>
  );
};

const BuildTheChart = (data, chartID) => {
  let colors;
  let children = data.categories.children;
  if (children.length === 1) {
    if (children[0].name === "Positive") {
      colors = ["#8ec774"];
    } else {
      colors = ["#f27a41"];
    }
  } else if (children.length === 2) {
    if (
      children[0].value > children[1].value ||
      children[0].value === children[1].value
    ) {
      colors = ["#8ec774", "#f27a41"];
    } else {
      colors = ["#f27a41", "#8ec774"];
    }
  }

  const chartDom = document.getElementById(chartID);
  const myChart = echarts.init(chartDom);

  let option = {
    tooltip: {
      show: true,
      trigger: "item",
      formatter: (params) => {
        let fullData = data.data.find(
          (obj) => obj.EndReasonAbbrev === params.data.name
        );
        if (fullData) {
          return fullData.EndReason + ": " + params.data.value;
        } else {
          return params.data.name + ": " + params.data.value;
        }
      },
    },
    legend: {
      show: true,
      type: "plain",
    },
    series: {
      type: "sunburst",
      emphasis: {
        focus: "ancestor",
      },
      color: colors,
      data: data.categories.children,
      radius: [0, "90%"],
      nodeClick: false,

      label: {
        rotate: 0,
      },
    },
  };

  option && colors && myChart.setOption(option);
};

export default EndOverview;

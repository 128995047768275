import React, { useState, useEffect } from "react";
import TabTemplate from "./TabTemplate/TabTemplate";
import { customThemes } from "../../Attrition/ChartThemes";
import Spinner from "react-bootstrap/Spinner";

function MonthlyTab({
  columnDefs,
  apiUrl,
  chartTitle,
  chartType,
  aggFunc,
  reportDescription,
  seriesChartTypes,
  selectedEndsType,
  setSelectedEndsType
}) {
  const [dates, setDates] = useState();
  const rangeChartSeries = [
    "DateLabel",
    "Headcount",
    selectedEndsType,
    "Attrition"
  ];

  useEffect(() => {
    if (!dates) {
      setDates(makeDates(12));
    }
    // eslint-disable-next-line
  }, [setDates]);

  const makeDates = (howMany) => {
    let dates = [];
    for (let index = 0; index <= howMany; index++) {
      if (index === 0) {
        let today = new Date();
        let firstDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          1
        );
        dates.push({
          value: firstDayOfMonth.toJSON(),
          label: firstDayOfMonth.toLocaleDateString(undefined, {
            month: "long",
            year: "numeric",
          }),
        });
        today.getMonth();
      } else {
        let previousMonthInArray = new Date(dates[index - 1].value);
        let previousMonthToAdd = new Date(
          previousMonthInArray.getFullYear(),
          previousMonthInArray.getMonth() - 1,
          1
        );
        dates.push({
          value: previousMonthToAdd.toJSON(),
          label: previousMonthToAdd.toLocaleDateString(undefined, {
            month: "long",
            year: "numeric",
          }),
        });
      }
    }
    return dates;
  };

  return (
    <>
      {dates ? (
        <TabTemplate
          dates={dates}
          startDateIndex={3}
          endDateIndex={0}
          dateType="Month"
          apiUrl={apiUrl}
          rangeChartSeries={rangeChartSeries}
          rangeChartID={"monthlyChart"}
          columnDefs={columnDefs}
          chartTitle={chartTitle}
          chartType={chartType}
          aggFunc={aggFunc}
          customThemes={customThemes}
          reportDescription={reportDescription}
          seriesChartTypes={seriesChartTypes}
          selectedEndsType={selectedEndsType}
          setSelectedEndsType={setSelectedEndsType}
        />
      ) : (
        <Spinner animation="border" />
      )}
    </>
  );
}

export default MonthlyTab;

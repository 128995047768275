export const customThemes = {
  myCustomTheme: {
    baseTheme: "ag-pastel",
    palette: {
      fills: ["#9dc3e6", "#ED9945", "#4472c4", "#E95C5C", "#8AE75C"],
      strokes: ["#7b7b7b"],
    },
    overrides: {
      common: {
        title: {
          fontSize: 22,
          fontFamily: "Arial, sans-serif",
        },
        axes: {
          number: {
            left: {
              label: {
                format: "c",
              },
            },
            right: {
              label: {
                format: "%",
              },
            },
          },
        },
      },
    },
  },
}
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

function ShiftDays({ selectedShift }) {
  return (
    <>
    <Row>
        <Col>
            <Row><Form.Label>Monday</Form.Label></Row>
            <input readOnly type={'checkbox'} checked={selectedShift.monday} />
        </Col>
        <Col>
            <Row><Form.Label>Tuesday</Form.Label></Row>
            <input readOnly type={'checkbox'} checked={selectedShift.tuesday} />
        </Col>
        <Col>
            <Row><Form.Label>Wednesday</Form.Label></Row>
            <input readOnly type={'checkbox'} checked={selectedShift.wednesday} />
        </Col>
        <Col>
            <Row><Form.Label>Thursday</Form.Label></Row>
            <input readOnly type={'checkbox'} checked={selectedShift.thursday} />
        </Col>
        <Col>
            <Row><Form.Label>Friday</Form.Label></Row>
            <input readOnly type={'checkbox'} checked={selectedShift.friday} />
        </Col>
        <Col>
            <Row><Form.Label>Saturday</Form.Label></Row>
            <input readOnly type={'checkbox'} checked={selectedShift.saturday} />
        </Col>
        <Col>
            <Row><Form.Label>Sunday</Form.Label></Row>
            <input readOnly type={'checkbox'} checked={selectedShift.sunday} />
        </Col>
    </Row>
    </>
  )
}

export default ShiftDays
import {Col, Form, Row } from "react-bootstrap";
import ReactSelect from "react-select";
import DepartmentDropdown from "../Dropdowns/DepartmentDropdown"
import OrderTypesDropdown from "../Dropdowns/OrderTypesDropdown";
import { HandleGroupByDropdownChange } from "../GraphGridTools";


export default function SearchFilters({
  loading,
  dates,
  startDate,
  endDate,
  HandleDateChange,
  selectedDepartments,
  HandleSelectChange,
  selectedBranches,
  selectedWorksites,
  worksiteOptions,
  selectedOrderTypes,
  orderTypeOptions,
  selectedVendors,
  vendorOptions,
  groupByOptions,
  gridApi,
  selectedEndsDrop,
  HandleEndsTypeChange
}){
  const endTypeOption = [
    {value: "PositiveEnd", label: "Positive"},
    {value: "NegativeEnd", label: "Negative"},
    {value: "All", label: "All Ends"}
  ];

  return(
    <>
       <Row>
          <Col sm={3}>
            <Form.Label>Date Range From:</Form.Label>
            <ReactSelect
              disabled={loading}
              options={dates}
              value={startDate}
              onChange={(e) => HandleDateChange(e, "start")}
            />
          </Col>
          <Col sm={3}>
            <Form.Label>To:</Form.Label>
            <ReactSelect
              disabled={loading}
              options={dates}
              value={endDate}
              onChange={(e) => HandleDateChange(e, "end")}
            />
          </Col>
          <Col sm={6}>
            <Form.Label>Department:</Form.Label>
            <DepartmentDropdown
              isMulti={false}
              selectedDepartments={selectedDepartments}
              onChange={(e) => HandleSelectChange(e, "department")}
              branchIDs={selectedBranches}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Work Sites:</Form.Label>
            <ReactSelect
              isMulti
              value={selectedWorksites}
              options={worksiteOptions}
              onChange={(e) => HandleSelectChange(e, "worksite")}
            />
          </Col>
          <Col>
            <Form.Label>Order Type:</Form.Label>
            <OrderTypesDropdown
              isMulti={true}
              selectedOrderTypes={selectedOrderTypes}
              orderTypeOptions={orderTypeOptions}
              onChange={(e) => HandleSelectChange(e, "ordertype")}
            />
          </Col>
          <Col>
            <Form.Label>Vendor:</Form.Label>
            <ReactSelect
              isMulti={true}
              value={selectedVendors}
              options={vendorOptions}
              onChange={(e) => HandleSelectChange(e, "vendor")}
            />
          </Col>
          <Col>
            <Form.Label>Group By:</Form.Label>
            {groupByOptions ? (
              <ReactSelect
                defaultValue={groupByOptions[0]}
                options={groupByOptions}
                onChange={(e) => HandleGroupByDropdownChange(e, gridApi)}
              />
            ) : (
              <ReactSelect options={[]} />
            )}
            <ReactSelect
              disabled={loading}
              value={selectedEndsDrop}
              options={endTypeOption}
              onChange={(e)=>HandleEndsTypeChange(e)}
              placeholder="select Ends Type"
            />
          </Col>
        </Row>
    </>
  )
}
const createCustomRangeChart = (gridApi, columns, querySelector, title, subTitle, type, seriesChartTypes, aggFunc) => {
    gridApi.createRangeChart({
      chartType: seriesChartTypes?'customCombo':type?type:'bar',
      cellRange: {
        columns: columns,
      },
      seriesChartTypes: seriesChartTypes,
      aggFunc: aggFunc?aggFunc:'sum',
      chartThemeOverrides: {
        common: {
          title: {
            enabled: true,
            text: title,
          },
          subtitle:{
            enabled: subTitle?true:false,
            text: subTitle
          },
          legend: {
            enabled: true,
            position: 'left'
          }
        },
      },
      chartContainer: document.querySelector(querySelector),
    });
  };

const setSelectedItems = (params, instanceName, defaultArray) => {
    let instance = params.api.getFilterInstance(instanceName)

    if(instance.getModel()){
        let selectedOptionsFromGrid = instance.getModel().values
        let formattedOptions = selectedOptionsFromGrid.map(string=>{return{value:string,label:string}})
        return formattedOptions;
    }else{
        return defaultArray
    }
}

const HandleGroupByDropdownChange = (e, gridApi) => { 
    // get all the column defs
    // find the one that matches the dropdown
    // add the row group attribute
    // remove it from all others
    // setColumnDefs to the updated column defs
    let columnDefs = gridApi.getColumnDefs();
    columnDefs.forEach(column=>{
      if(column.field===e.label||column.headerName===e.label){
        column.rowGroup = true;
        // column.sort = 'desc';
      }else{
        column.rowGroup = false;
        // column.sort = null;
      }
    })
    gridApi.setColumnDefs(columnDefs)
  }

const buildGroupByDropdownOptions = (params) => { 
    // grab all the columns from the grid
    // pull out the ones that have the chart type of category
    let dropdownOptions = [];
    let columns = params.columnApi.getColumns();
    columns.forEach(column=>{
        if(column.colDef.chartDataType==='category'){
          if(column.colDef.headerName){
            dropdownOptions.push({value:column, label:column.colDef.headerName})
          }else{
            dropdownOptions.push({value:column, label:column.colDef.field})
          }
        }
    })
    return dropdownOptions
}

const customChartThemes = {
    myCustomTheme: {
        baseTheme: 'ag-pastel',
        palette: {
          fills: ['#9dc3e6', '#a9d18e', '#ffd966', '#ed7d31'],
          strokes: ['#7b7b7b'],
        },
        overrides: {
            common: {
                title: {
                    fontSize: 22,
                    fontFamily: 'Arial, sans-serif'
                }
            }
        }
    }
};

const roundedTotal = (params) =>{
    let total = 0;
    params.values.forEach(value => total += value);
    total = total.toFixed(2)
    return Number(total);
}

const fixData = (data) =>{
  let fixedData = [];
  data.forEach(row=>{
    let index = fixedData.findIndex(obj => obj.DateLabel === row.DateLabel);
            if(index>-1){
                fixedData[index].Active += Number(row.Active)
                fixedData[index].Start += Number(row.Start)
                fixedData[index].FallOff += Number(row.FallOff)
                fixedData[index].End += Number(row.End)
            }else{
                let newRow = {
                    Active: Number(row.Active),
                    Start: Number(row.Start),
                    FallOff: Number(row.FallOff),
                    End: Number(row.End),
                    DateType: row.DateType,
                    DateLabel: row.DateLabel,
                    Date: row.Date,
                    Site: row.Site,
                    Shift: row.Shift,
                    Source: row.Source
                }
                fixedData[fixedData.length] = newRow;
            }
  })
  return fixedData;
}
const createSummaryGraphOptions = (data, title, chartType) => {
  let fixedData = fixData(data)
    let formatter = ({ value }) => (value == null ? '' : value.toFixed(0));
    let options = {
    autoSize: true,
    // below is the way we pull the JSON data in from the data page
    data: fixedData,
    // below is where you set all the styling components of the graph
    theme: {
        palette: {
            fills: ['#add8e6', '#79e177', '#edff58', '#ff5e12'],
            strokes: ['rgba(0, 117, 163, 0.9)', 'rgba(226, 188, 34, 0.9)'],
        },
        overrides: {
            bar: {
                series: {
                    strokeWidth: 0,
                    highlightStyle: {
                        series: {
                            strokeWidth: 1,
                            dimOpacity: 0.2,
                        },
                    },
                },
            },
        },
    },
    // obviously this is where you set the title and subtitle
    title: {
        text: title,
        fontSize: 18,
    },
    // this is where you'll set what type of graph you're showing
    series: [
        {
            type: chartType,
            xKey: 'DateLabel',
            yKey: 'Active',
            stacked: true,
            label: { formatter: formatter, placement: 'inside' }
        },
        {
            type: chartType,
            xKey: 'DateLabel',
            yKey: 'Start',
            stacked: true,
            label: { formatter: formatter, placement: 'inside' }
        },
        {
            type: chartType,
            xKey: 'DateLabel',
            yKey: 'FallOff',
            stacked: true,
            label: { formatter: formatter, placement: 'inside' }
        },
        {
            type: chartType,
            xKey: 'DateLabel',
            yKey: 'End',
            stacked: true,
            label: { formatter: formatter, placement: 'inside' }
        },
    ],
    // optionally provide a legend for the graph
    legend: {
        position: 'top',
    }
  }
  return options;
}

const defaultColumnDefinitions = [
  { field: 'DateLabel', headerName:'Date', rowGroup:true,  chartDataType: 'category' },
  { field: 'Active',chartDataType:'series', aggFunc: roundedTotal},
  { field: 'Start',chartDataType:'series', aggFunc: roundedTotal},
  { field: 'End',chartDataType:'series', aggFunc: roundedTotal},
  { field: 'FallOff',chartDataType:'series', aggFunc: roundedTotal},
  { field: 'EmployeeName', headerName:'Employee'},
  { field: 'Status', chartDataType: 'category'},
  { field: 'Shift', chartDataType: 'category' },
  { field: 'Site',filter: 'agSetColumnFilter', chartDataType: 'category',},
  { field: 'Source',filter: 'agSetColumnFilter',chartDataType:'category'},
  { field: 'Supervisor',chartDataType:'category'},
]

const chartToolPanelsDef = {panels:['settings']}


  export {
    createCustomRangeChart,
    setSelectedItems,
    HandleGroupByDropdownChange,
    buildGroupByDropdownOptions,
    customChartThemes,
    roundedTotal,
    createSummaryGraphOptions,
    defaultColumnDefinitions,
    chartToolPanelsDef
  }
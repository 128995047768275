import React, { useState, useEffect, useContext } from 'react'
import ReactSelect from 'react-select';
import Spinner from 'react-bootstrap/Spinner'
import CustomerContext from '../../../Context/CustomerContext';
import useFetchGet from '../../../Fetch/useFetchGet';

function DepartmentDropdown({ branchIDs, onChange, selectedDepartments, isMulti, onMenuOpen }) {
  const { cookies } = useContext(CustomerContext)
  const { REACT_APP_API_URL } = process.env
  const { data: unformattedDepartments, status } = useFetchGet((branchIDs&&`${REACT_APP_API_URL}/Reports/GetCustomerTree`), {CustomerID:cookies.get('customer').runAsCustomer?cookies.get('customer').runAsCustomer.CustomerId:cookies.get('customer').CustomerID})
  const [departments, setDepartments] = useState();

    useEffect(()=>{
        if(unformattedDepartments&&(status===200)){
          // adding flag to stop the overriting of defaults
          // the cookies default is the most important, if no cookie
          // then we want the department from the login audit
          let foundDefault = false;
          let formattedDepartments = [];
          buildDropdown(unformattedDepartments, 0, formattedDepartments)
          formattedDepartments.forEach(dept=>{
              if(cookies.get('department')&&cookies.get('department').CustomerID===dept.value.CustomerID){
                onChange(dept)
                foundDefault=true;
                return;
              }else if(!foundDefault&&cookies.get('customer').runAsCustomer&&(cookies.get('customer').runAsCustomer.CustomerId===dept.value.CustomerID)){
                onChange(dept)
                foundDefault=true;
                cookies.set('department',{selectedDepartment:dept.value})
                return;
              }else if(!foundDefault&&cookies.get('customer').CustomerID===dept.value.CustomerID){
                onChange(dept)
                return;
              }
            })
            setDepartments(formattedDepartments)
        }else if(status===204){
          setDepartments([])
        }
    // eslint-disable-next-line
    },[unformattedDepartments])

    function buildDropdown(data, depth, optionsArray){
      if(data.CustomerID===0){
        data.customerTrees.forEach(cust=>{
          buildDropdown(cust, depth+1, optionsArray)
        })
      }else{
        let option;
        if(depth===0){
          option = {value: data, label:data.DepartmentName+' | '+data.CustomerName}
          option.value.padding = depth*10;
        }else{
          option = {value: data, label:'  ⤷  '+data.DepartmentName+' | '+data.CustomerName}
          option.value.padding = depth*20;
        }
        optionsArray.push(option)
        data.customerTrees.forEach(cust=>{
          buildDropdown(cust, depth+1, optionsArray)
        })
      }
      return;
    }


    const handleCookieUpdateAndChange = (e) => { 
      cookies.set('department',e.value)
      onChange(e)
   }

   const CustomOption = ({ innerProps, isDisabled, label, value }) =>
  !isDisabled ? (
    <div {...innerProps}  className='custom-dropdown-department-option'><p style={{paddingLeft:`${value.padding}px`}}>{label}</p></div>
  ) : null;

  return (
    <>
    {departments?
    <ReactSelect components={{Option: CustomOption}} isMulti={isMulti} options={departments} value={selectedDepartments} onChange={(e)=>handleCookieUpdateAndChange(e)} onMenuOpen={onMenuOpen}/>
    :
    <Spinner animation='border' size='sm' />
    }
    </>
  )
}

export default DepartmentDropdown
import {ButtonGroup, Button} from "react-bootstrap";
import { BsBugFill, BsDownload, BsQuestionCircle } from "react-icons/bs";

export default function ActionButtonBox({
  showFilters,
  setShowFilters,
  weeklyData,
  onBtnExport,
  setTooltipPopupOpen
}){
  return(
    <ButtonGroup className="full-width">
      <Button
        title="Show all filters related to chart"
        variant="dark"
        onClick={() => setShowFilters(!showFilters)}
      >
        {showFilters ? "Hide Filters" : "Show Filters"}
      </Button>
      {weeklyData && (
        <Button
          variant="dark"
          onClick={() => onBtnExport()}
          title="Download grid data"
        >
          <BsDownload />
        </Button>
      )}
      <Button
        variant="dark"
        title="What is this chart?"
        onClick={() => setTooltipPopupOpen(true)}
      >
        <BsQuestionCircle />
      </Button>
      <Button
        variant="dark"
        title="Report a bug"
        onClick={(e) => {
          window.location.href = `mailto:${"Bug Reporting - DevMo! <01bd0080.employnet.com@amer.teams.ms>"}`;
          e.preventDefault();
        }}
      >
        <BsBugFill />
      </Button>
    </ButtonGroup>
  )
}
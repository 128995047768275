// used by DataTab, MonthlyTab, QuarterlyTab, and WeeklyTab to set consistent view

import React, { useContext, useMemo, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomerContext from "../../../../Context/CustomerContext";
import useFetchPost from "../../../../Fetch/useFetchPost";
import {Alert, Col, Row, Spinner } from "react-bootstrap";

import {
  createCustomRangeChart,
  buildGroupByDropdownOptions,
  customChartThemes,
  chartToolPanelsDef,
} from "../../GraphGridTools";
import TooltipPopup from "../../Buttons/TooltipPopup";
import ActionButtonBox from "../../Buttons/ActionButtonBox";
import SearchFilters from "../../Search/SearchFilters";

export default function TabTemplate({
  dates,
  startDateIndex,
  endDateIndex,
  dateType,
  apiUrl,
  rangeChartSeries,
  rangeChartID,
  columnDefs,
  chartTitle,
  chartType,
  dataTab,
  aggFunc,
  customThemes,
  reportDescription,
  seriesChartTypes,
  selectedEndsType,
  setSelectedEndsType,
}){
  const { cookies } = useContext(CustomerContext);
  const [groupByOptions, setGroupByOptions] = useState();
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [worksiteOptions, setWorksiteOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState();
  const [selectedWorksites, setSelectedWorksites] = useState();
  const [selectedOrderTypes, setSelectedOrderTypes] = useState();
  const [selectedVendors, setSelectedVendors] = useState();
  const [selectedBranches] = useState([
    cookies.get("customer").runAsCustomer
      ? cookies.get("customer").runAsCustomer.CustomerId
      : cookies.get("customer").CustomerID,
  ]);
  const [subTitle, setSubTitle] = useState();
  const [gridApi, setGridApi] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [postObj, setPostObj] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [tooltipPopupOpen, setTooltipPopupOpen] = useState(false);
  const { data: weeklyData, loading } = useFetchPost(
    postObj && (selectedDepartments || selectedWorksites) && apiUrl,
    null,
    postObj
  );

  const [selectedEndsDrop, setSelectedEndsDrop] = useState();

  const [chartThemes] = useState(["myCustomTheme", "ag-vivid"]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      editable: true,
      sortable: true,
      filter: "agMultiColumnFilter",
      floatingFilter: true,
      resizable: true,
    };
  }, []);

  useEffect(()=>{
    if(weeklyData){
      console.log(weeklyData);
    }
  },[weeklyData]);

  useEffect(() => {
    if (dates && selectedDepartments) {
      let baseSubTitleString = `Date Range: ${
        dates[startDateIndex].value.split("T")[0]
      } - ${dates[endDateIndex].value.split("T")[0]} | Department: ${
        selectedDepartments.value.DepartmentName
      }`;
      if (selectedWorksites && selectedWorksites.length > 0) {
        let siteNames = selectedWorksites.map((site) => site.value.AddressDesc);
        siteNames.join(" ");
        baseSubTitleString += " | Worksites: " + siteNames;
      }
      if (selectedOrderTypes && selectedOrderTypes.length > 0) {
        let orderNames = selectedOrderTypes.map((site) => site.value);
        orderNames.join(" ");
        baseSubTitleString += " | Order Types: " + orderNames;
      }
      setSubTitle(baseSubTitleString);
      if (!postObj) {
        setPostObj({
          StartDate: dates[startDateIndex].value,
          EndDate: dates[endDateIndex].value,
          DateType: dateType,
          CustomerId: selectedDepartments
            ? [selectedDepartments.value.CustomerID]
            : [],
          AddrId: selectedWorksites
            ? selectedWorksites.map((obj) => obj.value.AddrId)
            : [],
        });
      }
    }
    // eslint-disable-next-line
  }, [dates, selectedDepartments]);

  const buildFilterOptions = (params, filterInstance) => {
    // grab all the columns from the grid
    // pull out the ones that have the chart type of category
    let siteValues = params.api
      .getFilterInstance(filterInstance)
      .getFilterValues()
      .map((site) => {
        return { value: site.trim(), label: site };
      });
    return siteValues;
  };

  const setSelectedItems = (params, instanceName, defaultArray) => {
    let instance = params.api.getFilterInstance(instanceName);
    if (instance.getModel()) {
      let selectedOptionsFromGrid = instance.getModel().values;
      let formattedOptions = selectedOptionsFromGrid.map((string) => {
        return { value: string, label: string };
      });
      return formattedOptions;
    } else {
      return defaultArray;
    }
  };

  const onFirstDataRendered = (params) => {
    createCustomRangeChart(
      params.api,
      rangeChartSeries,
      `#${rangeChartID}`,
      chartTitle,
      subTitle,
      chartType,
      seriesChartTypes,
      aggFunc
    );
    setGroupByOptions(buildGroupByDropdownOptions(params));

    let orderTypeOptions = buildFilterOptions(params, "OrderType");
    setOrderTypeOptions(orderTypeOptions);
    setSelectedOrderTypes(orderTypeOptions);

    let vendorOptions = buildFilterOptions(params, "VendorName");
    setVendorOptions(vendorOptions);
    setSelectedVendors(vendorOptions);

    let worksiteOptions = buildFilterOptions(params, "Worksite");
    setWorksiteOptions(worksiteOptions);
    setSelectedWorksites(worksiteOptions);

    setGridApi(params.api);
    params.columnApi.autoSizeAllColumns(false);
  };

  const HandleDateChange = (e, dateType) => {
    if (postObj) {
      if (dateType === "start") {
        setStartDate(e);
        let newPostObj = { ...postObj };
        newPostObj.StartDate = e.value;
        setPostObj(newPostObj);
      } else {
        setEndDate(e);
        let newPostObj = { ...postObj };
        newPostObj.EndDate = e.value;
        setPostObj(newPostObj);
      }
    }
  };

  const HandleSelectChange = (e, selectName) => {
    if (selectName === "department") {
      if (postObj) {
        let newPostObj = { ...postObj };
        newPostObj.CustomerId = [e.value.CustomerID];
        setPostObj(newPostObj);
      }
      setSelectedDepartments(e);
    } else if (selectName === "worksite") {
      setSelectedWorksites(e);
      updateFilterModel(e, "Worksite");
    } else if (selectName === "ordertype") {
      setSelectedOrderTypes(e);
      updateFilterModel(e, "OrderType");
    } else if (selectName === "vendor") {
      setSelectedVendors(e);
      updateFilterModel(e, "VendorName");
    }
  };

  const HandleEndsTypeChange = (e) => {
    setSelectedEndsDrop(e);
    setSelectedEndsType(e.value);
    let newPostObj = {...postObj}
    setPostObj(newPostObj);
  }

  const updateFilterModel = (event, filterInstance) => {
    let newModel = event.map((source) => source.label);
    let sourceInstance = gridApi.getFilterInstance(filterInstance);
    sourceInstance.setModel({ values: newModel });
    sourceInstance.applyModel();
    gridApi.onFilterChanged();
  };

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const onDisplayedColumnsChanged = (params) => {
    params.columnApi.autoSizeAllColumns();
  };

  const onFilterChanged = (params) => {
    setSelectedOrderTypes(
      setSelectedItems(params, "OrderType", orderTypeOptions)
    );
    setSelectedVendors(setSelectedItems(params, "VendorName", vendorOptions));
    setSelectedWorksites(setSelectedItems(params, "Worksite", worksiteOptions));
  };

  return (
    <>
      {tooltipPopupOpen && (
        <TooltipPopup
          show={tooltipPopupOpen}
          setShow={setTooltipPopupOpen}
          reportTitle={chartTitle}
          reportDescription={reportDescription}
        />
      )}
      <div>
        <div>
          <Row>
            <Col>
              {loading && (
                <Alert> Loading data <Spinner animation="border" size="sm" /></Alert>
              )}
            </Col>
            <Col sm={3}>
              <ActionButtonBox
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                weeklyData={weeklyData}
                onBtnExport={onBtnExport}
                setTooltipPopupOpen={setTooltipPopupOpen}
              />
            </Col>
          </Row>
          <div className={showFilters ? "show" : "hide"}> 
            <SearchFilters
              loading={loading} 
              dates={dates}
              startDate={startDate}
              endDate={endDate}
              HandleDateChange={HandleDateChange}
              selectedDepartments={selectedDepartments}
              HandleSelectChange={HandleSelectChange}
              selectedBranches={selectedBranches}
              selectedWorksites={selectedWorksites}
              worksiteOptions={worksiteOptions}
              selectedOrderTypes={selectedOrderTypes}
              orderTypeOptions={orderTypeOptions}
              selectedVendors={selectedVendors}
              vendorOptions={vendorOptions}
              groupByOptions={groupByOptions}
              gridApi={gridApi}
              selectedEndsDrop={selectedEndsDrop}
              HandleEndsTypeChange={HandleEndsTypeChange}
            />
          </div>
        </div>
        {/*end header section*/}
        <>
          {selectedDepartments || selectedWorksites ? (
            <>
              <div id={rangeChartID} className="chart"></div>
              <div className="ag-theme-alpine grid">
                {dataTab ? (
                  <AgGridReact
                    multiSortKey={"ctrl"}
                    rowData={weeklyData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    chartThemes={chartThemes}
                    customChartThemes={
                      customThemes ? customThemes : customChartThemes
                    }
                    enableCharts={true}
                    enableRangeSelection={true}
                    suppressAggFuncInHeader={true}
                    onFirstDataRendered={onFirstDataRendered}
                    chartToolPanelsDef={chartToolPanelsDef}
                    sideBar={"columns"}
                    groupDisplayType={"singleColumn"}
                    onColumnRowGroupChanged={onDisplayedColumnsChanged}
                    onFilterChanged={onFilterChanged}
                  ></AgGridReact>
                ) : (
                  <AgGridReact
                    multiSortKey={"ctrl"}
                    rowData={weeklyData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    chartThemes={chartThemes}
                    customChartThemes={
                      customThemes ? customThemes : customChartThemes
                    }
                    enableCharts={true}
                    enableRangeSelection={true}
                    suppressAggFuncInHeader={true}
                    onFirstDataRendered={onFirstDataRendered}
                    chartToolPanelsDef={chartToolPanelsDef}
                    onFilterChanged={onFilterChanged}
                  ></AgGridReact>
                )}
              </div>
            </>
          ) : (
            <>
              {cookies.get("customer").runAsCustomer ? (
                <Alert className="text-center">
                  <Alert.Heading>
                    Please select a Department or WorkSite
                  </Alert.Heading>
                </Alert>
              ) : (
                <>
                  <Alert className="text-center">
                    <Alert.Heading>
                      You must impersonate a customer in order to see these
                      reports
                    </Alert.Heading>
                    <p>
                      In order to access the impersonation menu, please click
                      the dropdown next to your name in the header
                    </p>
                  </Alert>
                </>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
};

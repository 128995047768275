import { AgGridReact } from 'ag-grid-react';
import React, { useContext, useEffect, useState } from 'react';
import DepartmentDropdown from '../CustomComponents/Dropdowns/DepartmentDropdown';
import useFetchGet from '../../Fetch/useFetchGet';
import CustomerContext from '../../Context/CustomerContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';   
import { BsDownload } from 'react-icons/bs';
import Alert from 'react-bootstrap/Alert';
import './RequiredDocs.css'

function RequiredDocs() {
    const { REACT_APP_API_URL } = process.env;
    const { cookies } = useContext(CustomerContext)
    const [branchIDs] = useState([cookies.get('customer').BranchID])
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [customerID, setCustomerID] = useState()
    const [,setHeaders] = useState();
    const [data, setData] = useState();
    const [columnDefs, setColumnDefs] = useState();
    const [gridApi, setGridAPI] = useState();
    const { data: unformattedData } = useFetchGet((customerID&&`${REACT_APP_API_URL}/Reports/GetEmployeeRequiredDocument`), {CustomerID:customerID})
    const [defaultColDef] = useState({
        resizable: true,
        sortable: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        width: '125px',
      })

      const formatDate = (params) => params.value? params.value.split('T')[0]:''

    useEffect(()=>{
        if(unformattedData&&unformattedData.length>0&&unformattedData[0].Schema!==""){
            let headers = JSON.parse(unformattedData[0].Schema)
            let data = JSON.parse(unformattedData[0].Data)
            setHeaders(headers)
            setData(data)
            let requiredDocHeaders = headers.map(header=>{
                return{
                    field:header.DocType,  
                    wrapHeaderText: true,
                    cellClass: params => {
                        return params.value ? 'green-cell-background' : 'red-cell-background';
                    }
                }
            })
            let columnDefs = [
                {field:'EmployeeID'},
                {field:'AlternateID'},
                {field:'EmployeeName', headerName:'Employee'},
                {field:'StartDate', valueFormatter: formatDate},
                ...requiredDocHeaders,
                {field:'CustomerName', headerName:'Customer'},
                {field:'DepartmentName', headerName:'Department'},
                {field:'Worksite'},
                {field:'VendorName', headerName:'Vendor'},
                {field:'Supervisor'},
                {field:'OrderType'},
                {field:'Shift'},
                ]
            setColumnDefs(columnDefs)
        }else{
            setData(null)
        }
    },[unformattedData, setHeaders])

    const HandleCustomerChange = (e) => { 
        setCustomerID(e.value.CustomerID)
        setSelectedDepartment(e)
     }

    const onFirstDataRendered = (params) => { 
        // params.columnApi.autoSizeAllColumns(true);
        setGridAPI(params.api)
     }

     const onBtnExport = () => {
        gridApi.exportDataAsCsv();
    };

  return (
    <>
    {branchIDs&&
        <Row className='department-dropdown-wrapper'>
            <Col sm={11}>
                <DepartmentDropdown branchIDs={branchIDs} onChange={(e)=>HandleCustomerChange(e)} isMulti={false} selectedDepartments={selectedDepartment} />
            </Col>
            <Col sm={1}>
                <Button variant="dark" onClick={()=>onBtnExport()} title='Download grid data'><BsDownload /></Button>
            </Col>
        </Row>
    }

    {selectedDepartment?
        <div id="myGrid" className="ag-theme-alpine grid filter-grid">
            <AgGridReact
                rowData={data}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onFirstDataRendered={onFirstDataRendered}
                ></AgGridReact>
        </div>
        :
        cookies.get('customer').runAsCustomer?
            <Alert className='text-center'>
                <Alert.Heading>Please select a Department or WorkSite</Alert.Heading>
            </Alert>
        :
            <Alert className='text-center'>
                <Alert.Heading>You must impersonate a customer in order to see these reports</Alert.Heading>
                <p>In order to access the impersonation menu, please click the dropdown next to your name in the header</p>
            </Alert>
        }
    </>
  )
}

export default RequiredDocs
import React from 'react'
import ShiftDays from './ShiftDays'

function ShiftDaysLogic({ selectedShift }) {

  return (
    <>
        <ShiftDays
            selectedShift={selectedShift}
        ></ShiftDays>
    </>
  )
}

export default ShiftDaysLogic
import { useState } from "react";
import AttritionDashboardView from "./AttritionDashboardView";

export default function AttritionDashboard() {
  //for fetching data depending on tab
  const { REACT_APP_API_URL } = process.env;
  const tabApiUrl = `${REACT_APP_API_URL}/Reports/GetCustomerAttrition`;
  const [selectedTab, setSelectedTab] = useState("Monthly");
  const [selectedEndsType, setSelectedEndsType] = useState("Ends");

  const [seriesChartTypes] = useState([
    { colId: "Headcount", chartType: "groupedColumn", secondaryAxis: false },
    { colId: selectedEndsType, chartType: "groupedColumn", secondaryAxis: false },
    { colId: "Attrition", chartType: "line", secondaryAxis: true },
  ]);
  
  const [reportDescription] = useState(
    <div>
      <p>This report is displayed as a grouped column with a line overlay.</p>
      <p>
        {" "}
        The left or 'y' axis displays the employees active, and those who have
        ended their assignments. The bottom or 'x' axis is the date range
        selected. The right or 'secondary y' axis displays the attrition
        percentage, indicated in the chart by the blue line.
      </p>
      <p>
        {" "}
        This report shows the attrition percentage for each month. Attrition is
        calculated by taking the total number of ends in a month and dividing by
        the average weekly headcount from that month. This provides an estimated
        percentage of the total workforce that has been replaced over the course
        of the month.
      </p>
    </div>
  );

  const roundedTotal = (params) => {
    let total = 0;
    params.values.forEach((value) => (total += value));
    total = total.toFixed(2);
    return Number(total);
  };

  const roundedNeg = (parms) => {
    let total = 0;
    parms.values.forEach(value => {
      if(value === true){
        total+=1;
      }
    });
    return Number(total);
  };

  const roundedPos = (parms) => {
    let total = 0;
    parms.values.forEach(value => {
      if(value === true){
        total+=1;
      }
    });
    return Number(total);
  };

  const [columnDefs] = useState([
    {
      field: "DateLabel",
      chartDataType: "category",
      headerName: "Date",
      rowGroup: true,
    },
    { field: "Headcount", chartDataType: "series", aggFunc: roundedTotal },
    { field: "Ends", chartDataType: "series", aggFunc: roundedTotal },
    {
      field: "Attrition",
      chartDataType: "series",
      aggFunc: roundedTotal,
      valueFormatter: (params) => (params.value * 100).toFixed() + "%",
    },
    { field: "NegativeEnd", 
      chartDataType: "series", aggFunc: roundedNeg,
    },
    { field: "PositiveEnd", 
      chartDataType: "series", aggFunc: roundedPos,
    },
    { field: "EmployeeName", headerName: "Employee" },
    {
      field: "EmployeeId",
      headerName: "EmployeeID",
      chartDataType: "category",
      filter: "agSetColumnFilter",
    },
    {
      field: "AlternateID",
      chartDataType: "category",
      filter: "agSetColumnFilter",
    },
    { field: "Status", chartDataType: "category" },
    { field: "Shift", chartDataType: "category" },
    {
      field: "VendorName",
      filter: "agSetColumnFilter",
      chartDataType: "category",
    },
    {
      field: "Worksite",
      filter: "agSetColumnFilter",
      chartDataType: "category",
    },
    {
      field: "Department",
      filter: "agSetColumnFilter",
      chartDataType: "category",
    },
    {
      field: "OrderType",
      filter: "agSetColumnFilter",
      chartDataType: "category",
    },
    { field: "Supervisor", chartDataType: "category" },
  ]);

  return (
    <AttritionDashboardView
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      selectedEndsType={selectedEndsType}
      setSelectedEndsType={setSelectedEndsType}
      columnDefs={columnDefs}
      tabApiUrl={tabApiUrl}
      reportDescription={reportDescription}
      seriesChartTypes={seriesChartTypes}
    />
  );
}
import React, { useState, useEffect } from 'react'
import TabTemplate from './TabTemplate/TabTemplate';
import useFetchGet from '../../../Fetch/useFetchGet';
import Spinner from 'react-bootstrap/Spinner';

function QuarterlyTab({ columnDefs, apiUrl, rangeChartSeries, chartTitle, chartType, seriesChartTypes, customThemes, reportDescription }) {
    const { REACT_APP_API_URL } = process.env;
    const [dates, setDates] = useState();
    const { data: unformattedQuarters } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetQuarters`)

    useEffect(()=>{
        if(unformattedQuarters){
        let formattedQuarters = unformattedQuarters.map(obj=>{return {value:obj.Value,label:obj.Label}})
          setDates(formattedQuarters)
        }
      // eslint-disable-next-line
      },[unformattedQuarters])


  return (
    <>
    {dates?
        <TabTemplate 
            dates={dates}
            startDateIndex={2}
            endDateIndex={0} 
            dateType='Quarter' 
            apiUrl={apiUrl} 
            rangeChartSeries={rangeChartSeries}
            rangeChartID={'quarterlyChart'}
            columnDefs={columnDefs}
            chartTitle={chartTitle}
            chartType={chartType}
            seriesChartTypes={seriesChartTypes}
            customThemes={customThemes}
            reportDescription={reportDescription}
            />            

    :
        <Spinner animation='border' />
    }
    </>
  )
}

export default QuarterlyTab
import React, { useState, useEffect } from 'react'
import TabTemplate from './TabTemplate/TabTemplate';
import useFetchGet from '../../../Fetch/useFetchGet';
import Spinner from 'react-bootstrap/Spinner';

function WeeklyTab({ columnDefs, apiUrl, rangeChartSeries, chartTitle, chartType, seriesChartTypes, aggFunc, customThemes, reportDescription }) {
    const { REACT_APP_API_URL } = process.env;
    const [dates, setDates] = useState();
    const { data: unformattedWeekendDates } = useFetchGet(`${REACT_APP_API_URL}/Reports/GetSundays`, {'weeks':'52'})

    useEffect(()=>{
        if(unformattedWeekendDates){
          let formattedWeekendDates = unformattedWeekendDates.map(obj=>{return {value:obj.TheDate,label:obj.DateValue}})
          setDates(formattedWeekendDates)
        }
      // eslint-disable-next-line
      },[unformattedWeekendDates])


  return (
    <>
    {dates?
        <TabTemplate 
            dates={dates}
            startDateIndex={3}
            endDateIndex={0} 
            dateType='week' 
            apiUrl={apiUrl} 
            rangeChartSeries={rangeChartSeries}
            rangeChartID={'weeklyChart'}
            columnDefs={columnDefs}
            chartTitle={chartTitle}
            chartType={chartType}
            seriesChartTypes={seriesChartTypes}
            aggFunc={aggFunc}
            customThemes={customThemes}
            reportDescription={reportDescription}
        />
    :
        <Spinner animation='border' />
    }
    </>
  )
}

export default WeeklyTab